(async () => {
      await import(/* webpackChunkName: "uikit-css" */'./uikit/uikit.scss');
})();


import uikit from './uikit/uikitCustom.js';

import Icons from 'uikit/dist/js/uikit-icons.min.js';
uikit.use(Icons);

export default uikit;
